import React from 'react'

const CreateCompany:React.FC = () => {
  return (
    <div>
      
    </div>
  )
}

export default CreateCompany
