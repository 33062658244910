export const tagTypes =
    [
        'WarehouseEntries',
        'WarehouseExits',
        'Sales',
        'CashOut',
        'CashEntry',
        'Returnable',
        'WarehouseTransfers',
        'Returns',
        'CashTransfers',
        'MyGoods'
    ];
