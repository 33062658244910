import React from 'react'

const MyPage:React.FC = () => {
  return (
    <div>
      My page
    </div>
  )
}

export default MyPage
